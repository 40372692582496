/** @jsx jsx */
import { jsx, get } from "theme-ui"
import * as React from "react"
import { Link } from "gatsby"

const GridItem: React.FC<React.PropsWithChildren<{ to: string; className?: string }>> = ({
  children,
  to,
  ...props
}) => (
  <Link
    to={to}
    sx={{
      position: `relative`,
      // @ts-ignore
      "> div img": {
        transition: `all 0.3s ease 0s !important`,
      },
      "&:hover": {
        "> div img": {
          transform: `scale(1.05)`,
        },
      },
      "&:focus": {
        outline: `none`,
        "> div:after": {
          boxShadow: (t) => `inset 0 0 0 7px ${get(t, `colors.white`)}`,
          zIndex: 10,
        },
      },
      "@media screen and (prefers-reduced-motion: reduce)": {
        "&:hover": {
          "> div img": {
            transform: `scale(1)`,
          },
          "> div:after": {
            boxShadow: (t) => `inset 0 0 0 7px ${get(t, `colors.white`)}`,
            zIndex: 10,
          },
        },
      },
      variant: `grid-item`,
    }}
    {...props}
  >
    {children}
  </Link>
)

export default GridItem
