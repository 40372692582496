/** @jsx jsx */
import { jsx } from "theme-ui"
import * as React from "react"
import { PageProps } from "gatsby"
import { IGatsbyImageData, GatsbyImage } from "gatsby-plugin-image"
import Layout from "@lekoarts/gatsby-theme-jodie/src/components/layout"
import Seo from "@lekoarts/gatsby-theme-jodie/src/components/seo"
import GridItem from "@lekoarts/gatsby-theme-jodie/src/components/grid-item"
import GridGroup from "./grid-group"
import locales from "@lekoarts/gatsby-theme-jodie/src/locales"
import { visuallyHidden } from "@lekoarts/gatsby-theme-jodie/src/styles/utils"

type DataProps = {
  projects: {
    nodes: {
      shortTitle: string
      slug: string
      cover: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData
        }
      }
    }[]
  }
}

const Project: React.FC<PageProps<DataProps>> = ({ data: { projects }, location }) => {
  return (
    <Layout>
      <Seo title="Projects" pathname={location.pathname} />
      <h1 sx={visuallyHidden} data-testid="page-title">
        {locales.projects}
      </h1>
      <div
      >
        <GridGroup sx={{marginBottom: "1rem"}}>
          <GridItem to={projects.nodes[0].slug} key={projects.nodes[0].slug} data-testid={projects.nodes[0].shortTitle}>
            <GatsbyImage image={projects.nodes[0].cover.childImageSharp.gatsbyImageData} alt={projects.nodes[0].shortTitle} />
          </GridItem>
          <GridItem to={projects.nodes[1].slug} key={projects.nodes[1].slug} data-testid={projects.nodes[1].shortTitle}>
            <GatsbyImage image={projects.nodes[1].cover.childImageSharp.gatsbyImageData} alt={projects.nodes[1].shortTitle} />
          </GridItem>
        </GridGroup>

        <GridItem to={projects.nodes[2].slug} key={projects.nodes[2].slug} data-testid={projects.nodes[2].shortTitle} sx={{marginBottom: "1rem"}}>
          <GatsbyImage image={projects.nodes[2].cover.childImageSharp.gatsbyImageData} alt={projects.nodes[2].shortTitle} />
        </GridItem>


        <GridGroup sx={{marginBottom: "1rem"}}>
          <GridItem to={projects.nodes[3].slug} key={projects.nodes[3].slug} data-testid={projects.nodes[3].shortTitle}>
            <GatsbyImage image={projects.nodes[3].cover.childImageSharp.gatsbyImageData} alt={projects.nodes[3].shortTitle} />
          </GridItem>
          <GridItem to={projects.nodes[4].slug} key={projects.nodes[4].slug} data-testid={projects.nodes[4].shortTitle}>
            <GatsbyImage image={projects.nodes[4].cover.childImageSharp.gatsbyImageData} alt={projects.nodes[4].shortTitle} />
          </GridItem>
        </GridGroup>

        <GridGroup sx={{marginBottom: "1rem"}}>
          <GridItem to={projects.nodes[5].slug} key={projects.nodes[5].slug} data-testid={projects.nodes[5].shortTitle}>
            <GatsbyImage image={projects.nodes[5].cover.childImageSharp.gatsbyImageData} alt={projects.nodes[5].shortTitle} />
          </GridItem>
          <GridItem to={projects.nodes[6].slug} key={projects.nodes[6].slug} data-testid={projects.nodes[6].shortTitle}>
            <GatsbyImage image={projects.nodes[6].cover.childImageSharp.gatsbyImageData} alt={projects.nodes[6].shortTitle} />
          </GridItem>
        </GridGroup>

        <GridGroup sx={{marginBottom: "1rem"}}>
          <GridItem to={projects.nodes[7].slug} key={projects.nodes[7].slug} data-testid={projects.nodes[7].shortTitle}>
            <GatsbyImage image={projects.nodes[7].cover.childImageSharp.gatsbyImageData} alt={projects.nodes[7].shortTitle} />
          </GridItem>
          <GridItem to={projects.nodes[8].slug} key={projects.nodes[8].slug} data-testid={projects.nodes[8].shortTitle}>
            <GatsbyImage image={projects.nodes[8].cover.childImageSharp.gatsbyImageData} alt={projects.nodes[8].shortTitle} />
          </GridItem>
        </GridGroup>

        <GridItem to={projects.nodes[9].slug} key={projects.nodes[9].slug} data-testid={projects.nodes[9].shortTitle}>
          <GatsbyImage image={projects.nodes[9].cover.childImageSharp.gatsbyImageData} alt={projects.nodes[9].shortTitle} />
        </GridItem>
      </div>
    </Layout>
  )}

export default Project
